import React from 'react'
import { graphql } from 'gatsby'
import { l, t } from '../lib/locale'
import { brandURL } from '../lib/brand'
import { categoryURL } from '../lib/category'
import { PageLayout,
  ContentBlock, ContentHighLightBlocks, ContentHighLightBullets,
  ContentHighLightBlock, InstagramBlock, ContentBlockBrands,
  ContentBlockHomePageHero, ProductCarousel, ContentBlockBanner,
  ContentBlockService, ContentBlockTestimonials
 } from '../components'

const HomepageTemplate = ({ data, pageContext }) => {
  const { lang, page } = pageContext

  const showBullets = false

  return (
    <PageLayout lang={lang} switcher={page} fullwidth={true}>
      <>
        <ContentBlockHomePageHero
          lang={lang} />

        <ProductCarousel
          lang={lang}
          title={l('block-homepage-products-gifts1', lang)}
          products={data.gifts1.edges.map(({ node }) => node)} />

        <ContentBlock
          lang={lang}
          title={l('block-advice-title', lang)}
          paragraphs={[
            l('block-advice-paragraph1', lang),
            l('block-advice-paragraph2', lang),
            l('block-advice-paragraph3', lang)
          ]}
          image={'20221108-advice.jpg'}
          buttonTitle="block-advice-link"
          buttonLink="interioradvice"
          imageLeft={false} />

        <ProductCarousel
          lang={lang}
          title={l('block-homepage-products-gifts2', lang)}
          products={data.gifts2.edges.map(({ node }) => node)} />

        <ContentBlockTestimonials
          lang={lang} />

        <ProductCarousel
          lang={lang}
          title={l('block-homepage-products-gifts3', lang)}
          products={data.gifts3.edges.map(({ node }) => node)} />

        <ContentHighLightBlocks
          title={'new'}
          highlights={[
            {
              title: l('block-homepage-new-left-top', lang),
              image: '20221108-left-top.jpg',
              id_category: 269
            },
            {
              title: l('block-homepage-new-right-top', lang),
              image: '20221108-right-top.jpg',
              page: 'meet-the-team'
            },
            {
              title: l('block-homepage-new-left-bottom', lang),
              image: '20221108-left-bottom.jpg',
              id_category: 268
            },
            {
              title: l('block-homepage-new-right-bottom', lang),
              image: '20221108-right-bottom.jpg',
              id_category: 77
            }
          ]}
          lang={lang} />

        <ContentBlockBrands
          lang={lang} />

        <ContentBlockService
          image={'20201114-giftwrapping.jpg'}
          title="block-service-title"
          lang={lang} />
      </>
    </PageLayout>
  )
}

export default HomepageTemplate

export const query = graphql`
  query HomePageTemplateQuery($homepage_trend_brand: Int, $homepage_category: Int, $homepage_category_brands: [Int], $homepage_new_left: Int, $homepage_new_right: Int, $homepage_products: [Int], $homepage_products_gifts1: [Int], $homepage_products_gifts2: [Int], $homepage_products_gifts3: [Int], $homepage_products_gifts4: [Int]) {
    highlights: allShopProduct(limit: 10, filter: {id_product: {in: $homepage_products}}, sort: {fields: date_add, order: DESC}) {
      edges {
        node {
          ...ProductSearchItem
        }
      }
    }
    gifts1: allShopProduct(filter: {id_product: {in: $homepage_products_gifts1}}) {
      edges {
        node {
          ...ProductSearchItem
        }
      }
    }
    gifts2: allShopProduct(filter: {id_product: {in: $homepage_products_gifts2}}) {
      edges {
        node {
          ...ProductSearchItem
        }
      }
    }
    gifts3: allShopProduct(filter: {id_product: {in: $homepage_products_gifts3}}) {
      edges {
        node {
          ...ProductSearchItem
        }
      }
    }
    gifts4: allShopProduct(filter: {id_product: {in: $homepage_products_gifts4}}) {
      edges {
        node {
          ...ProductSearchItem
        }
      }
    }

    homepage_new_left: shopBrand(id_manufacturer: {eq: $homepage_new_left}) {
      ...BrandItem
    }
    homepage_new_right: shopBrand(id_manufacturer: {eq: $homepage_new_right}) {
      ...BrandItem
    }
    homepage_trend_brand: shopBrand(id_manufacturer: {eq: $homepage_trend_brand}) {
      ...BrandItem
    }
    homepage_category: shopCategory(id_category: {eq: $homepage_category}) {
      ...CategoryItem
    }
    homepage_category_brands: allShopBrand(filter: {id_manufacturer: {in: $homepage_category_brands}}) {
      edges {
        node {
          ...BrandItem
        }
      }
    }
  }
`
